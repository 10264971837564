import axios from 'axios';

const state = {
  user: null,
  notification: null,
};


const getters = {
  isAuthenticated: state => !!state.user,
  StateUser: state => state.user,
};


const actions = {
  async Register({ dispatch }, form) {
    await axios.post('auth/register', form)
  },
  async LogIn({ commit }, User) {
    await axios.post('auth/login', { email: User.email, password: User.password, totp: User.totp })
      .then((res) => {
        if (res.data.code == 'INVALID_CREDENTIALS') {
          commit('setNotification', { message: res.data.message, status: 'error' });
          res.send({ message: res.data.message, status: 'error' })
        } else if (res.data.code == 'EMAIL_NOT_VERIFIED') {
          commit('setNotification', { message: res.data.message, status: 'warning' });
          res.send({ message: res.data.message, status: 'warning' })
        } else if (res.data.code == 'TOTP_INVALID') {
          commit('setNotification', { message: res.data.message, status: 'warning' });
          res.send({ message: res.data.message, status: 'warning' })
        } else if (res.data.code == 'USER_BANNED') {
          commit('setNotification', { message: res.data.message, status: 'warning' });
          res.send({ message: res.data.message, status: 'warning' })
        } else {
          commit('setUser', res.data)
          localStorage.setItem('labelhub_access_token', res.data.access_token)
        }
        /* commit('setUser', res.data)
        localStorage.setItem('labelhub_access_token', res.data.access_token) */
      })
      .catch((err) => {
        commit('setNotification', err.response.data);
        this.errors.push(err);
      });
  },
  async LogInTFA({ commit }, User) {
    await axios.post('auth/login', { email: User.email, password: User.password })
      .then((res) => {
        commit('setUser', res.data)
        localStorage.setItem('labelhub_access_token', res.data.access_token)
      })
      .catch((err) => {
        commit('setNotification', err.response.data);
        this.errors.push(err);
      });
  },
  LogOut({ commit }) {
    let user = null;
    commit('LogOut', user)
    localStorage.setItem('labelhub_access_token', '')
  },
};


const mutations = {
  setUser(state, userData) {
    state.user = userData
  },
  setNotification(state, notificationData) {
    state.notification = notificationData
  },
  LogOut(state) {
    state.user = null;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
};
