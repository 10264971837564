import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";

// Modules
import themeConfig from './theme-config'
import auth from './modules/auth';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    themeConfig,
    auth,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState()]
})