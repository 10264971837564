import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/",
      component: () => import("@/view/home"),
      meta: {
        title: "Homepage",
        requiresAuth: false,
        layout: "full",
      },
    },
    {
      path: "/login",
      component: () => import("@/view/login"),
      meta: {
        title: "Login",
        requiresAuth: false,
        layout: "full",
      },
    },
    {
      path: "/register",
      component: () => import("@/view/register"),
      meta: {
        title: "Register",
        requiresAuth: false,
        layout: "full",
      },
    },
    {
      path: "/auth/verify-email",
      component: () => import("@/view/register/verifyEmail"),
      meta: {
        title: "Verify Email",
        requiresAuth: false,
        layout: "full",
      },
    },
    {
      path: "/dashboard",
      component: () => import("@/view/dashboard"),
      meta: {
        title: "Dashboard",
        requiresAuth: true,
        layout: "full",
      },
    },
    {
      path: "/shipping",
      component: () => import("@/view/couriers"),
      meta: {
        title: "Shipping",
        requiresAuth: true,
        layout: "full",
      },
    },
    {
      path: "/orders",
      component: () => import("@/view/orders"),
      meta: {
        title: "Orders",
        requiresAuth: true,
        layout: "full",
      },
    },
    {
      path: "/orders/create",
      component: () => import("@/view/orders/createOrder"),
      meta: {
        title: "Create Order",
        requiresAuth: true,
        layout: "full",
      },
    },
    {
      path: "/address-book",
      component: () => import("@/view/addressBook"),
      meta: {
        title: "Address Book",
        requiresAuth: true,
        layout: "full",
      },
    },
    {
      path: "/plans",
      component: () => import("@/view/plans"),
      meta: {
        title: "Plans",
        requiresAuth: true,
        layout: "full",
      },
    },
    {
      path: "/profile",
      component: () => import("@/view/profile"),
      meta: {
        title: "Profile",
        requiresAuth: true,
        layout: "full",
      },
    },
    {
      path: "/deposits",
      component: () => import("@/view/deposits"),
      meta: {
        title: "Deposits",
        requiresAuth: true,
        layout: "full",
      },
    },
    {
      path: "/pages/blank-page",
      name: "blank-page",
      component: () => import("@/view/pages/blank"),
      meta: {
        title: "LabelHub",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/error-404",
      name: "error-404",
      component: () => import("@/view/pages/errors/404"),
      meta: {
        title: 'LabelHub',
        layout: "full",
      },
    },
    {
      path: '*',
      component: () => import("@/view/pages/errors/404"),
      meta: {
        title: 'LabelHub',
        layout: "full",
      },
    },
  ]
})




router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuth = store.getters.isAuthenticated;


  if (requiresAuth && !isAuth) {
    next('/login');
  } else if (to.path == '/login' && isAuth) {
    next('/');
  } else {
    next();
  }

  window.document.title = to.meta.title;
});



export default router