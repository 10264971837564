import Vue from 'vue'
import VueCompositionAPI, { createApp, h } from '@vue/composition-api'

import axios from 'axios';
import store from './store'
import router from './router'
import App from './App.vue'

// Vue Page Transition
import VuePageTransition from 'vue-page-transition'


import moment from 'moment';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast)


import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

// QR code component for deposit addresses
import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);

// Bootstrap
import { BootstrapVueIcons, PopoverPlugin, ToastPlugin, ModalPlugin, SidebarPlugin, TooltipPlugin, TabsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Highlightjs
import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/mono-blue.css';

// Apexcharts
import VueApexCharts from 'vue-apexcharts';

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)


axios.defaults.withCredentials = false
axios.defaults.baseURL = 'https://labelhub.app/api/';
//axios.defaults.baseURL = 'https://labelhub.app/api';


axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('labelhub_access_token');

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);


axios.interceptors.response.use((response) => {
  if (response.data.session_status && response.data.session_status == 'expired') {
    store.dispatch("LogOut");
    router.push({ path: "/login" });
  } else {
    return response
  }
});


Vue.filter('formatDateWithHours', function (value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY HH:MM')
  }
});

Vue.filter('formatHours', function (value) {
  if (value) {
    return moment(String(value)).format('HH:MM')
  }
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
});

Vue.filter('toCurrency', function (value) {
  /*   if (typeof value !== "number") {
        return value;
    } */
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(value);
});


// Bootstrap Plugin
Vue.use(TabsPlugin)
Vue.use(TooltipPlugin)
Vue.use(SidebarPlugin)
Vue.use(PopoverPlugin)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVueIcons)

// Vue Page Transition
Vue.use(VuePageTransition)

// Highlight
Vue.use(VueHighlightJS)

// Apexchart
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Style
require('@/assets/icons/iconly/index.min.css')
require('@/assets/icons/remix-icon/index.min.css')
require('@/assets/scss/app.scss')

// Composition Api
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
